export const PEOPLE = [
  {
    age: 38,
    gender: "male",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 4.6,
    height_inches: 71,
    income_bucket: 2,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 30,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 4.9,
    height_inches: 71,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 37,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 4.2222222222,
    height_inches: 74,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "No",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 39,
    gender: "male",
    iq_percentile: 0.2818021201,
    avg_physical_attractiveness_rating: 3.5,
    height_inches: 68,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 36,
    gender: "female",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 3.4,
    height_inches: 64,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "No",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 21,
    gender: "male",
    iq_percentile: 0.2234982332,
    avg_physical_attractiveness_rating: 4.5,
    height_inches: 77,
    income_bucket: 1,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "No",
    politics: "Liberal",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 25,
    gender: "female",
    iq_percentile: 0.1104240283,
    avg_physical_attractiveness_rating: 7.3636363636,
    height_inches: 62,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Neutral",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 33,
    gender: "female",
    iq_percentile: 0.0494699647,
    avg_physical_attractiveness_rating: 5.7142857143,
    height_inches: 60,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 32,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 6.3333333333,
    height_inches: 62,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Apolitical",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 30,
    gender: "male",
    iq_percentile: 0.0494699647,
    avg_physical_attractiveness_rating: 2.2352941176,
    height_inches: 72,
    income_bucket: 9,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 43,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 4,
    height_inches: 73,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "No",
    politics: "Apolitical",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 28,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 3.4285714286,
    height_inches: 63,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "No",
    politics: "Liberal",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 34,
    gender: "male",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 1.625,
    height_inches: 69,
    income_bucket: 1,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 0,
    political_tolerance: "Neutral",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 37,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 6,
    height_inches: 65,
    income_bucket: 9,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Neutral",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 30,
    gender: "male",
    iq_percentile: 0.0494699647,
    avg_physical_attractiveness_rating: 4.2222222222,
    height_inches: 68,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 3,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 38,
    gender: "male",
    iq_percentile: 0.4381625442,
    avg_physical_attractiveness_rating: 3.6363636364,
    height_inches: 68,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 32,
    gender: "male",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 5.375,
    height_inches: 71,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 25,
    gender: "female",
    iq_percentile: 0.4381625442,
    avg_physical_attractiveness_rating: 4.125,
    height_inches: 64,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "No",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 31,
    gender: "female",
    iq_percentile: 0.4381625442,
    avg_physical_attractiveness_rating: 4.7,
    height_inches: 62,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 27,
    gender: "male",
    iq_percentile: 0.4381625442,
    avg_physical_attractiveness_rating: 2.25,
    height_inches: 65,
    income_bucket: 5,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 27,
    gender: "male",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 4.75,
    height_inches: 70,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 24,
    gender: "male",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 3.8,
    height_inches: 70,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 3,
    political_tolerance: "Neutral",
    politics: "Liberal",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 36,
    gender: "female",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 4.25,
    height_inches: 65,
    income_bucket: 2,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "No",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 27,
    gender: "male",
    iq_percentile: 0.4381625442,
    avg_physical_attractiveness_rating: 7.8571428571,
    height_inches: 68,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 4,
    political_tolerance: "Neutral",
    politics: "Apolitical",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 31,
    gender: "female",
    iq_percentile: 0.2818021201,
    avg_physical_attractiveness_rating: 3.8181818182,
    height_inches: 70,
    income_bucket: 3,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "No",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 28,
    gender: "female",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 3.6,
    height_inches: 67,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 29,
    gender: "male",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 3.2222222222,
    height_inches: 69,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 45,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 4,
    height_inches: 71,
    income_bucket: 2,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Apolitical",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 27,
    gender: "male",
    iq_percentile: 0.0795053004,
    avg_physical_attractiveness_rating: 3.0952380952,
    height_inches: 65,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "No",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 27,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 4.3636363636,
    height_inches: 68,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Neutral",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 25,
    gender: "male",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 2.7,
    height_inches: 71,
    income_bucket: 3,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 36,
    gender: "female",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 5,
    height_inches: 68,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 27,
    gender: "male",
    iq_percentile: 0.0494699647,
    avg_physical_attractiveness_rating: 3.1666666667,
    height_inches: 63,
    income_bucket: 6,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Neutral",
    politics: "Other",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 30,
    gender: "male",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 5.25,
    height_inches: 67,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 33,
    gender: "female",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 5.7272727273,
    height_inches: 67,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "No",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 35,
    gender: "female",
    iq_percentile: 0.0494699647,
    avg_physical_attractiveness_rating: 5.8333333333,
    height_inches: 61,
    income_bucket: 5,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 33,
    gender: "male",
    iq_percentile: 0.0494699647,
    avg_physical_attractiveness_rating: 2.5714285714,
    height_inches: 67,
    income_bucket: 1,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 3,
    political_tolerance: "Neutral",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 41,
    gender: "male",
    iq_percentile: 0.2234982332,
    avg_physical_attractiveness_rating: 2.8333333333,
    height_inches: 70,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 32,
    gender: "male",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 5.8888888889,
    height_inches: 69,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Neutral",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 36,
    gender: "male",
    iq_percentile: 0.0494699647,
    avg_physical_attractiveness_rating: 4.8,
    height_inches: 72,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 29,
    gender: "male",
    iq_percentile: 0.1104240283,
    avg_physical_attractiveness_rating: 1.9333333333,
    height_inches: 74,
    income_bucket: 3,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Neutral",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 35,
    gender: "male",
    iq_percentile: 0.4381625442,
    avg_physical_attractiveness_rating: 1.7692307692,
    height_inches: 75,
    income_bucket: 3,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Neutral",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 31,
    gender: "female",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 2.4285714286,
    height_inches: 66,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "unattractive",
  },
  {
    age: 38,
    gender: "male",
    iq_percentile: 0.4381625442,
    avg_physical_attractiveness_rating: 3.8,
    height_inches: 68,
    income_bucket: 3,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 30,
    gender: "female",
    iq_percentile: 0.2818021201,
    avg_physical_attractiveness_rating: 5.1818181818,
    height_inches: 62,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Neutral",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 32,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 4.5384615385,
    height_inches: 64,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 33,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 5.1176470588,
    height_inches: 70,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 33,
    gender: "male",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 3.3571428571,
    height_inches: 71,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 27,
    gender: "female",
    iq_percentile: 0.1457597173,
    avg_physical_attractiveness_rating: 3.8,
    height_inches: 66,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Neutral",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 35,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 3.6666666667,
    height_inches: 65,
    income_bucket: 6,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Neutral",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 27,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 4.2857142857,
    height_inches: 68,
    income_bucket: 3,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "No",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 25,
    gender: "female",
    iq_percentile: 0.1104240283,
    avg_physical_attractiveness_rating: 3.5714285714,
    height_inches: 61,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 30,
    gender: "female",
    iq_percentile: 0.2234982332,
    avg_physical_attractiveness_rating: 4.1818181818,
    height_inches: 61,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "No",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 31,
    gender: "male",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 4.625,
    height_inches: 71,
    income_bucket: 2,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Neutral",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 31,
    gender: "female",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 6.25,
    height_inches: 59,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Apolitical",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "attractive",
  },
  {
    age: 36,
    gender: "male",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 6.5454545455,
    height_inches: 68,
    income_bucket: 9,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 3,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 22,
    gender: "female",
    iq_percentile: 0.4381625442,
    avg_physical_attractiveness_rating: 2.2857142857,
    height_inches: 62,
    income_bucket: 1,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Neutral",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 53,
    gender: "female",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 2,
    height_inches: 63,
    income_bucket: 3,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "No",
    politics: "Moderate",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 32,
    gender: "male",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 4,
    height_inches: 72,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 34,
    gender: "male",
    iq_percentile: 0.1104240283,
    avg_physical_attractiveness_rating: 2.8461538462,
    height_inches: 71,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Neutral",
    politics: "Apolitical",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 32,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 3.125,
    height_inches: 68,
    income_bucket: 8,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 42,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 3.5714285714,
    height_inches: 73,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 37,
    gender: "male",
    iq_percentile: 0.1802120141,
    avg_physical_attractiveness_rating: 5.4,
    height_inches: 74,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 3,
    political_tolerance: "Neutral",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 35,
    gender: "female",
    iq_percentile: 0.1457597173,
    avg_physical_attractiveness_rating: 5.5384615385,
    height_inches: 67,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Socialist",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 32,
    gender: "male",
    iq_percentile: 0.2818021201,
    avg_physical_attractiveness_rating: 4.625,
    height_inches: 71,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 33,
    gender: "male",
    iq_percentile: 0.0494699647,
    avg_physical_attractiveness_rating: 5.5714285714,
    height_inches: 72,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 34,
    gender: "male",
    iq_percentile: 0.0494699647,
    avg_physical_attractiveness_rating: 3.8888888889,
    height_inches: 78,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 4,
    political_tolerance: "Neutral",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 43,
    gender: "male",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 2.3333333333,
    height_inches: 64,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "No",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 31,
    gender: "female",
    iq_percentile: 0.2818021201,
    avg_physical_attractiveness_rating: 4.2307692308,
    height_inches: 69,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Neutral",
    politics: "Liberal",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 31,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 5.4615384615,
    height_inches: 68,
    income_bucket: 3,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Apolitical",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 29,
    gender: "female",
    iq_percentile: 0.0795053004,
    avg_physical_attractiveness_rating: 4.6,
    height_inches: 66,
    income_bucket: 5,
    uses_nicotine: "Yes",
    uses_alcohol: "No",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Socialist",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 28,
    gender: "male",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 4.7,
    height_inches: 67,
    income_bucket: 3,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 34,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 5.625,
    height_inches: 69,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Neutral",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 38,
    gender: "male",
    iq_percentile: 0.0795053004,
    avg_physical_attractiveness_rating: 2.6666666667,
    height_inches: 66,
    income_bucket: 8,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 40,
    gender: "female",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 6.3571428571,
    height_inches: 66,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Neutral",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "attractive",
  },
  {
    age: 37,
    gender: "male",
    iq_percentile: 0.0494699647,
    avg_physical_attractiveness_rating: 6.4166666667,
    height_inches: 64,
    income_bucket: 7,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "No",
    has_kids: "Yes",
    attractiveness: "attractive",
  },
  {
    age: 50,
    gender: "male",
    iq_percentile: 0.0494699647,
    avg_physical_attractiveness_rating: 3.7777777778,
    height_inches: 67,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Conservative",
    wants_kids: "No",
    has_kids: "Yes",
    attractiveness: "unattractive",
  },
  {
    age: 38,
    gender: "male",
    iq_percentile: 0.1457597173,
    avg_physical_attractiveness_rating: 7.875,
    height_inches: 71,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 23,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 4,
    height_inches: 68,
    income_bucket: 3,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 33,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 5.347826087,
    height_inches: 67,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 31,
    gender: "female",
    iq_percentile: 0.2234982332,
    avg_physical_attractiveness_rating: 6.8333333333,
    height_inches: 62,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Neutral",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 37,
    gender: "male",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 5,
    height_inches: 68,
    income_bucket: 8,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 38,
    gender: "female",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 3.5263157895,
    height_inches: 63,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "No",
    politics: "Apolitical",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "unattractive",
  },
  {
    age: 42,
    gender: "male",
    iq_percentile: 0.4381625442,
    avg_physical_attractiveness_rating: 3.625,
    height_inches: 73,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 37,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 3.1428571429,
    height_inches: 64,
    income_bucket: 4,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 38,
    gender: "male",
    iq_percentile: 0.1104240283,
    avg_physical_attractiveness_rating: 4.0909090909,
    height_inches: 72,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 28,
    gender: "female",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 6.6363636364,
    height_inches: 62,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Apolitical",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "attractive",
  },
  {
    age: 34,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 3.6,
    height_inches: 62,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "unattractive",
  },
  {
    age: 31,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 7.9166666667,
    height_inches: 69,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Neutral",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "attractive",
  },
  {
    age: 41,
    gender: "male",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 4.1666666667,
    height_inches: 72,
    income_bucket: 9,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 31,
    gender: "female",
    iq_percentile: 0.1104240283,
    avg_physical_attractiveness_rating: 5.3076923077,
    height_inches: 59,
    income_bucket: 3,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Conservative",
    wants_kids: "No",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 32,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 5.0769230769,
    height_inches: 65,
    income_bucket: 3,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "No",
    politics: "Liberal",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 31,
    gender: "male",
    iq_percentile: 0.2818021201,
    avg_physical_attractiveness_rating: 2.4285714286,
    height_inches: 72,
    income_bucket: 8,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 37,
    gender: "female",
    iq_percentile: 0.2818021201,
    avg_physical_attractiveness_rating: 6.4,
    height_inches: 62,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 27,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 5.5384615385,
    height_inches: 63,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 1,
    political_tolerance: "No",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 34,
    gender: "male",
    iq_percentile: 0.1802120141,
    avg_physical_attractiveness_rating: 5.0666666667,
    height_inches: 72,
    income_bucket: 5,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 33,
    gender: "male",
    iq_percentile: 0.4381625442,
    avg_physical_attractiveness_rating: 5.1363636364,
    height_inches: 66,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 20,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 7.4285714286,
    height_inches: 64,
    income_bucket: 1,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Libertarian",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 34,
    gender: "female",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 5.6428571429,
    height_inches: 69,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 34,
    gender: "female",
    iq_percentile: 0.0494699647,
    avg_physical_attractiveness_rating: 7.25,
    height_inches: 64,
    income_bucket: 9,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 40,
    gender: "female",
    iq_percentile: 0.2818021201,
    avg_physical_attractiveness_rating: 3,
    height_inches: 66,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "No",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "unattractive",
  },
  {
    age: 32,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 2.8333333333,
    height_inches: 73,
    income_bucket: 3,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Neutral",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 29,
    gender: "female",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 5.8571428571,
    height_inches: 66,
    income_bucket: 2,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 52,
    gender: "male",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 4.7333333333,
    height_inches: 72,
    income_bucket: 9,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Conservative",
    wants_kids: "No",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 40,
    gender: "female",
    iq_percentile: 0.4381625442,
    avg_physical_attractiveness_rating: 6.0769230769,
    height_inches: 62,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 33,
    gender: "male",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 3.9,
    height_inches: 68,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Socialist",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "unattractive",
  },
  {
    age: 25,
    gender: "male",
    iq_percentile: 0.0494699647,
    avg_physical_attractiveness_rating: 2.1428571429,
    height_inches: 68,
    income_bucket: 1,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Neutral",
    politics: "Other",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 33,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 4.04,
    height_inches: 66,
    income_bucket: 9,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 1,
    political_tolerance: "No",
    politics: "Socialist",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 38,
    gender: "female",
    iq_percentile: 0.2818021201,
    avg_physical_attractiveness_rating: 2.4666666667,
    height_inches: 67,
    income_bucket: 3,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "unattractive",
  },
  {
    age: 41,
    gender: "male",
    iq_percentile: 0.2234982332,
    avg_physical_attractiveness_rating: 4.1538461538,
    height_inches: 68,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Libertarian",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 40,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 6.6,
    height_inches: 64,
    income_bucket: 8,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Neutral",
    politics: "Socialist",
    wants_kids: "No",
    has_kids: "Yes",
    attractiveness: "attractive",
  },
  {
    age: 40,
    gender: "male",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 3.0909090909,
    height_inches: 70,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 65,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 6.8181818182,
    height_inches: 63,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "No",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 36,
    gender: "female",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 6.3333333333,
    height_inches: 62,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 48,
    gender: "female",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 4.8,
    height_inches: 67,
    income_bucket: 9,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "No",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 33,
    gender: "male",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 4.4,
    height_inches: 68,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 39,
    gender: "male",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 4.0769230769,
    height_inches: 70,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 39,
    gender: "male",
    iq_percentile: 0.0212014134,
    avg_physical_attractiveness_rating: 7.25,
    height_inches: 73,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "attractive",
  },
  {
    age: 35,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 5.5,
    height_inches: 72,
    income_bucket: 6,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Apolitical",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 37,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 4.2857142857,
    height_inches: 66,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "No",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 29,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 5.4117647059,
    height_inches: 65,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Neutral",
    politics: "Liberal",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 33,
    gender: "male",
    iq_percentile: 0.2818021201,
    avg_physical_attractiveness_rating: 3.7272727273,
    height_inches: 67,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Libertarian",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "unattractive",
  },
  {
    age: 28,
    gender: "male",
    iq_percentile: 0.0494699647,
    avg_physical_attractiveness_rating: 4.9230769231,
    height_inches: 70,
    income_bucket: 1,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 3,
    political_tolerance: "Neutral",
    politics: "Other",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 40,
    gender: "female",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 4.7142857143,
    height_inches: 66,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 30,
    gender: "female",
    iq_percentile: 0.0795053004,
    avg_physical_attractiveness_rating: 4.25,
    height_inches: 66,
    income_bucket: 3,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 31,
    gender: "male",
    iq_percentile: 0.1802120141,
    avg_physical_attractiveness_rating: 4.6153846154,
    height_inches: 71,
    income_bucket: 1,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "No",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 34,
    gender: "female",
    iq_percentile: 0.4381625442,
    avg_physical_attractiveness_rating: 2.6666666667,
    height_inches: 67,
    income_bucket: 3,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 23,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 3.375,
    height_inches: 66,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "No",
    politics: "Socialist",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 34,
    gender: "male",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 5.2727272727,
    height_inches: 74,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Apolitical",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 28,
    gender: "female",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 3.6923076923,
    height_inches: 64,
    income_bucket: 3,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "No",
    politics: "Apolitical",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 36,
    gender: "female",
    iq_percentile: 0.0795053004,
    avg_physical_attractiveness_rating: 3.5333333333,
    height_inches: 65,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "unattractive",
  },
  {
    age: 37,
    gender: "male",
    iq_percentile: 0.0212014134,
    avg_physical_attractiveness_rating: 2.5833333333,
    height_inches: 75,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Conservative",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 34,
    gender: "male",
    iq_percentile: 0.0494699647,
    avg_physical_attractiveness_rating: 2.9333333333,
    height_inches: 68,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "No",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "unattractive",
  },
  {
    age: 36,
    gender: "female",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 4.8571428571,
    height_inches: 62,
    income_bucket: 4,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Neutral",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 34,
    gender: "female",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 5.2,
    height_inches: 62,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Neutral",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 38,
    gender: "male",
    iq_percentile: 0.2234982332,
    avg_physical_attractiveness_rating: 2.0769230769,
    height_inches: 68,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 30,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 5.1875,
    height_inches: 68,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 51,
    gender: "female",
    iq_percentile: 0.2234982332,
    avg_physical_attractiveness_rating: 4.6470588235,
    height_inches: 59,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 34,
    gender: "male",
    iq_percentile: 0.0212014134,
    avg_physical_attractiveness_rating: 2.1333333333,
    height_inches: 64,
    income_bucket: 1,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 35,
    gender: "male",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 4.1176470588,
    height_inches: 67,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Neutral",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 31,
    gender: "female",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 4.6923076923,
    height_inches: 65,
    income_bucket: 3,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Apolitical",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 29,
    gender: "female",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 2.3846153846,
    height_inches: 67,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 31,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 5.5833333333,
    height_inches: 71,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 40,
    gender: "male",
    iq_percentile: 0.4381625442,
    avg_physical_attractiveness_rating: 2.3571428571,
    height_inches: 68,
    income_bucket: 9,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Libertarian",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "unattractive",
  },
  {
    age: 39,
    gender: "female",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 4.3529411765,
    height_inches: 62,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "No",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 36,
    gender: "male",
    iq_percentile: 0.1802120141,
    avg_physical_attractiveness_rating: 5.6666666667,
    height_inches: 69,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 29,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 5.75,
    height_inches: 62,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 4,
    political_tolerance: "Yes",
    politics: "Apolitical",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 30,
    gender: "female",
    iq_percentile: 0.1802120141,
    avg_physical_attractiveness_rating: 4.9285714286,
    height_inches: 60,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Neutral",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 33,
    gender: "male",
    iq_percentile: 0.1104240283,
    avg_physical_attractiveness_rating: 2.1666666667,
    height_inches: 69,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Neutral",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 35,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 3.6923076923,
    height_inches: 64,
    income_bucket: 6,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "No",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 27,
    gender: "female",
    iq_percentile: 0.4381625442,
    avg_physical_attractiveness_rating: 4.0909090909,
    height_inches: 66,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 34,
    gender: "male",
    iq_percentile: 0.2818021201,
    avg_physical_attractiveness_rating: 3.2857142857,
    height_inches: 73,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "unattractive",
  },
  {
    age: 35,
    gender: "male",
    iq_percentile: 0.1104240283,
    avg_physical_attractiveness_rating: 4.6666666667,
    height_inches: 73,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "No",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 26,
    gender: "female",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 5.8888888889,
    height_inches: 66,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 1,
    political_tolerance: "No",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 40,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 3.5,
    height_inches: 68,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 31,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 4.4615384615,
    height_inches: 72,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 35,
    gender: "female",
    iq_percentile: 0.0494699647,
    avg_physical_attractiveness_rating: 6.3,
    height_inches: 68,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "attractive",
  },
  {
    age: 30,
    gender: "male",
    iq_percentile: 0.4381625442,
    avg_physical_attractiveness_rating: 3.7777777778,
    height_inches: 71,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 40,
    gender: "female",
    iq_percentile: 0.2234982332,
    avg_physical_attractiveness_rating: 3.7857142857,
    height_inches: 63,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Apolitical",
    wants_kids: "No",
    has_kids: "Yes",
    attractiveness: "unattractive",
  },
  {
    age: 29,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 4.0909090909,
    height_inches: 73,
    income_bucket: 5,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Neutral",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 33,
    gender: "female",
    iq_percentile: 0.1802120141,
    avg_physical_attractiveness_rating: 4.625,
    height_inches: 62,
    income_bucket: 2,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 0,
    political_tolerance: "Neutral",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 35,
    gender: "male",
    iq_percentile: 0.1104240283,
    avg_physical_attractiveness_rating: 2.4285714286,
    height_inches: 68,
    income_bucket: 3,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 31,
    gender: "female",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 5.75,
    height_inches: 65,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 47,
    gender: "female",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 4.0909090909,
    height_inches: 64,
    income_bucket: 8,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 26,
    gender: "female",
    iq_percentile: 0.1802120141,
    avg_physical_attractiveness_rating: 4.1818181818,
    height_inches: 62,
    income_bucket: 1,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 1,
    political_tolerance: "No",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 31,
    gender: "female",
    iq_percentile: 0.1802120141,
    avg_physical_attractiveness_rating: 1.8,
    height_inches: 67,
    income_bucket: 4,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "unattractive",
  },
  {
    age: 40,
    gender: "male",
    iq_percentile: 0.2234982332,
    avg_physical_attractiveness_rating: 3.2222222222,
    height_inches: 70,
    income_bucket: 4,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Neutral",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 30,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 5.9,
    height_inches: 63,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Neutral",
    politics: "Liberal",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 42,
    gender: "male",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 3.6363636364,
    height_inches: 73,
    income_bucket: 9,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "unattractive",
  },
  {
    age: 37,
    gender: "female",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 6.3,
    height_inches: 61,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "No",
    politics: "Moderate",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 34,
    gender: "female",
    iq_percentile: 0.0795053004,
    avg_physical_attractiveness_rating: 3.5454545455,
    height_inches: 67,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 29,
    gender: "female",
    iq_percentile: 0.4381625442,
    avg_physical_attractiveness_rating: 4.2857142857,
    height_inches: 65,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "No",
    politics: "Socialist",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 26,
    gender: "female",
    iq_percentile: 0.1802120141,
    avg_physical_attractiveness_rating: 4.7272727273,
    height_inches: 67,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "No",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 31,
    gender: "male",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 3.2857142857,
    height_inches: 72,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 3,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "unattractive",
  },
  {
    age: 36,
    gender: "male",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 3.6666666667,
    height_inches: 72,
    income_bucket: 7,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 3,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 28,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 5.5384615385,
    height_inches: 66,
    income_bucket: 8,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 28,
    gender: "male",
    iq_percentile: 0.2234982332,
    avg_physical_attractiveness_rating: 6.1111111111,
    height_inches: 74,
    income_bucket: 5,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 40,
    gender: "female",
    iq_percentile: 0.0494699647,
    avg_physical_attractiveness_rating: 7.2,
    height_inches: 64,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "attractive",
  },
  {
    age: 30,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 4.6363636364,
    height_inches: 65,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "No",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 40,
    gender: "female",
    iq_percentile: 0.1802120141,
    avg_physical_attractiveness_rating: 6.7222222222,
    height_inches: 67,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 29,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 4.9285714286,
    height_inches: 66,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "No",
    politics: "Socialist",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 31,
    gender: "male",
    iq_percentile: 0.1802120141,
    avg_physical_attractiveness_rating: 3.0588235294,
    height_inches: 73,
    income_bucket: 4,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "unattractive",
  },
  {
    age: 26,
    gender: "female",
    iq_percentile: 0.2818021201,
    avg_physical_attractiveness_rating: 5.625,
    height_inches: 66,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 35,
    gender: "male",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 2,
    height_inches: 72,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "unattractive",
  },
  {
    age: 32,
    gender: "male",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 6.5,
    height_inches: 71,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 29,
    gender: "female",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 2.8333333333,
    height_inches: 63,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 27,
    gender: "female",
    iq_percentile: 0.2234982332,
    avg_physical_attractiveness_rating: 4.8333333333,
    height_inches: 66,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Neutral",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 32,
    gender: "male",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 6.4166666667,
    height_inches: 70,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "attractive",
  },
  {
    age: 29,
    gender: "male",
    iq_percentile: 0.0494699647,
    avg_physical_attractiveness_rating: 3.6923076923,
    height_inches: 70,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 33,
    gender: "male",
    iq_percentile: 0.2818021201,
    avg_physical_attractiveness_rating: 3.5333333333,
    height_inches: 71,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "unattractive",
  },
  {
    age: 26,
    gender: "male",
    iq_percentile: 0.0061837456,
    avg_physical_attractiveness_rating: 3,
    height_inches: 74,
    income_bucket: 2,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "No",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 26,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 4.625,
    height_inches: 63,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 42,
    gender: "male",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 6.875,
    height_inches: 74,
    income_bucket: 8,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "attractive",
  },
  {
    age: 33,
    gender: "male",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 2.8181818182,
    height_inches: 71,
    income_bucket: 5,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 29,
    gender: "female",
    iq_percentile: 0.0061837456,
    avg_physical_attractiveness_rating: 2.3,
    height_inches: 66,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 3,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 37,
    gender: "male",
    iq_percentile: 0.2818021201,
    avg_physical_attractiveness_rating: 5.1111111111,
    height_inches: 66,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 26,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 8.1428571429,
    height_inches: 62,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 25,
    gender: "female",
    iq_percentile: 0.4381625442,
    avg_physical_attractiveness_rating: 7.2,
    height_inches: 69,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 41,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 3.5,
    height_inches: 68,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Neutral",
    politics: "Moderate",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 31,
    gender: "male",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 4.8888888889,
    height_inches: 76,
    income_bucket: 3,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 3,
    political_tolerance: "Yes",
    politics: "Libertarian",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 38,
    gender: "male",
    iq_percentile: 0.0061837456,
    avg_physical_attractiveness_rating: 4.8823529412,
    height_inches: 69,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 33,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 4.75,
    height_inches: 68,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 29,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 3.25,
    height_inches: 71,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Neutral",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 23,
    gender: "male",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 3.8,
    height_inches: 67,
    income_bucket: 1,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Neutral",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 40,
    gender: "female",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 3.5,
    height_inches: 62,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "unattractive",
  },
  {
    age: 32,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 5.7142857143,
    height_inches: 64,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 50,
    gender: "male",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 2.8,
    height_inches: 71,
    income_bucket: 9,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Neutral",
    politics: "Socialist",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 33,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 6.5384615385,
    height_inches: 60,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 1,
    political_tolerance: "Neutral",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 35,
    gender: "female",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 4.5714285714,
    height_inches: 64,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 33,
    gender: "female",
    iq_percentile: 0.1104240283,
    avg_physical_attractiveness_rating: 4,
    height_inches: 62,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Neutral",
    politics: "Libertarian",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 26,
    gender: "female",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 2.7,
    height_inches: 59,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 39,
    gender: "female",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 3.9,
    height_inches: 66,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 26,
    gender: "male",
    iq_percentile: 0.4381625442,
    avg_physical_attractiveness_rating: 2.2727272727,
    height_inches: 67,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 31,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 6.3,
    height_inches: 68,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Libertarian",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 30,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 4.3333333333,
    height_inches: 63,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 30,
    gender: "male",
    iq_percentile: 0.1457597173,
    avg_physical_attractiveness_rating: 4.0714285714,
    height_inches: 70,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Conservative",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 39,
    gender: "male",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 3.4285714286,
    height_inches: 64,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Neutral",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 31,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 6,
    height_inches: 65,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Apolitical",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 35,
    gender: "male",
    iq_percentile: 0.2234982332,
    avg_physical_attractiveness_rating: 4.6153846154,
    height_inches: 65,
    income_bucket: 2,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Libertarian",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 30,
    gender: "male",
    iq_percentile: 0.0061837456,
    avg_physical_attractiveness_rating: 5,
    height_inches: 67,
    income_bucket: 8,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 37,
    gender: "male",
    iq_percentile: 0.2818021201,
    avg_physical_attractiveness_rating: 2.7142857143,
    height_inches: 67,
    income_bucket: 7,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 32,
    gender: "female",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 5.125,
    height_inches: 62,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 36,
    gender: "female",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 5.8,
    height_inches: 61,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "No",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 35,
    gender: "female",
    iq_percentile: 0.0494699647,
    avg_physical_attractiveness_rating: 3.1111111111,
    height_inches: 77,
    income_bucket: 1,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 32,
    gender: "female",
    iq_percentile: 0.4381625442,
    avg_physical_attractiveness_rating: 7.2,
    height_inches: 61,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Neutral",
    politics: "Moderate",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 27,
    gender: "male",
    iq_percentile: 0.0795053004,
    avg_physical_attractiveness_rating: 3.2,
    height_inches: 68,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "unattractive",
  },
  {
    age: 21,
    gender: "female",
    iq_percentile: 0.1457597173,
    avg_physical_attractiveness_rating: 4.5555555556,
    height_inches: 62,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 24,
    gender: "male",
    iq_percentile: 0.1457597173,
    avg_physical_attractiveness_rating: 2.6666666667,
    height_inches: 67,
    income_bucket: 1,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "No",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 44,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 3.375,
    height_inches: 67,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 34,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 2.1666666667,
    height_inches: 69,
    income_bucket: 3,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 39,
    gender: "male",
    iq_percentile: 0.4381625442,
    avg_physical_attractiveness_rating: 4.4545454545,
    height_inches: 70,
    income_bucket: 3,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 30,
    gender: "female",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 7.3571428571,
    height_inches: 68,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Neutral",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 34,
    gender: "male",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 5.1875,
    height_inches: 71,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 29,
    gender: "female",
    iq_percentile: 0.4381625442,
    avg_physical_attractiveness_rating: 7.2857142857,
    height_inches: 61,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 26,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 2.4285714286,
    height_inches: 72,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "No",
    politics: "Liberal",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 20,
    gender: "male",
    iq_percentile: 0.0212014134,
    avg_physical_attractiveness_rating: 2.8333333333,
    height_inches: 64,
    income_bucket: 1,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Neutral",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 24,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 3.5555555556,
    height_inches: 71,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 37,
    gender: "male",
    iq_percentile: 0.2818021201,
    avg_physical_attractiveness_rating: 3.3529411765,
    height_inches: 70,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 36,
    gender: "female",
    iq_percentile: 0.2818021201,
    avg_physical_attractiveness_rating: 2.8,
    height_inches: 64,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Neutral",
    politics: "Liberal",
    wants_kids: "No",
    has_kids: "Yes",
    attractiveness: "unattractive",
  },
  {
    age: 31,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 3.5,
    height_inches: 76,
    income_bucket: 2,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 41,
    gender: "male",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 3,
    height_inches: 70,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 38,
    gender: "male",
    iq_percentile: 0.1802120141,
    avg_physical_attractiveness_rating: 4.875,
    height_inches: 70,
    income_bucket: 3,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "No",
    politics: "Socialist",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 27,
    gender: "male",
    iq_percentile: 0.2818021201,
    avg_physical_attractiveness_rating: 5.25,
    height_inches: 70,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 3,
    political_tolerance: "No",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 32,
    gender: "male",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 6,
    height_inches: 62,
    income_bucket: 5,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 4,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 29,
    gender: "male",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 4.6666666667,
    height_inches: 75,
    income_bucket: 1,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 26,
    gender: "male",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 3.5454545455,
    height_inches: 63,
    income_bucket: 3,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 28,
    gender: "female",
    iq_percentile: 0.1457597173,
    avg_physical_attractiveness_rating: 6.6666666667,
    height_inches: 70,
    income_bucket: 1,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Neutral",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 29,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 6,
    height_inches: 69,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 40,
    gender: "male",
    iq_percentile: 0.2234982332,
    avg_physical_attractiveness_rating: 4.1428571429,
    height_inches: 77,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 34,
    gender: "male",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 4.4285714286,
    height_inches: 72,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Libertarian",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 25,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 5.2666666667,
    height_inches: 63,
    income_bucket: 3,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Neutral",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 28,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 5.1875,
    height_inches: 64,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 32,
    gender: "male",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 3.9166666667,
    height_inches: 68,
    income_bucket: 3,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 38,
    gender: "female",
    iq_percentile: 0.0061837456,
    avg_physical_attractiveness_rating: 6.4,
    height_inches: 63,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "attractive",
  },
  {
    age: 30,
    gender: "female",
    iq_percentile: 0.2818021201,
    avg_physical_attractiveness_rating: 6.1111111111,
    height_inches: 60,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Neutral",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "attractive",
  },
  {
    age: 28,
    gender: "female",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 6.2727272727,
    height_inches: 63,
    income_bucket: 3,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 1,
    political_tolerance: "Neutral",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 30,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 4.2,
    height_inches: 65,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Libertarian",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 49,
    gender: "male",
    iq_percentile: 0.0061837456,
    avg_physical_attractiveness_rating: 1.5714285714,
    height_inches: 67,
    income_bucket: 2,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Apolitical",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 33,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 5.25,
    height_inches: 69,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 35,
    gender: "male",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 4.3333333333,
    height_inches: 70,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Libertarian",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 32,
    gender: "female",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 6.875,
    height_inches: 61,
    income_bucket: 5,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "attractive",
  },
  {
    age: 40,
    gender: "male",
    iq_percentile: 0.4381625442,
    avg_physical_attractiveness_rating: 3.5714285714,
    height_inches: 71,
    income_bucket: 4,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Libertarian",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 27,
    gender: "male",
    iq_percentile: 0.2818021201,
    avg_physical_attractiveness_rating: 4.8181818182,
    height_inches: 64,
    income_bucket: 2,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 33,
    gender: "male",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 3.9090909091,
    height_inches: 66,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 33,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 6.5555555556,
    height_inches: 67,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 35,
    gender: "female",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 5,
    height_inches: 67,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Neutral",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 33,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 5.1428571429,
    height_inches: 68,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 34,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 5.0909090909,
    height_inches: 68,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 40,
    gender: "female",
    iq_percentile: 0.4381625442,
    avg_physical_attractiveness_rating: 3,
    height_inches: 68,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Apolitical",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "unattractive",
  },
  {
    age: 30,
    gender: "male",
    iq_percentile: 0.1104240283,
    avg_physical_attractiveness_rating: 3.1,
    height_inches: 70,
    income_bucket: 2,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 33,
    gender: "female",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 6.7272727273,
    height_inches: 65,
    income_bucket: 4,
    uses_nicotine: "Yes",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Neutral",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 36,
    gender: "male",
    iq_percentile: 0.2818021201,
    avg_physical_attractiveness_rating: 3.2666666667,
    height_inches: 72,
    income_bucket: 2,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 38,
    gender: "male",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 3.5454545455,
    height_inches: 66,
    income_bucket: 6,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 31,
    gender: "female",
    iq_percentile: 0.1457597173,
    avg_physical_attractiveness_rating: 5.125,
    height_inches: 61,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 42,
    gender: "male",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 5.0588235294,
    height_inches: 76,
    income_bucket: 8,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 25,
    gender: "female",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 5.8461538462,
    height_inches: 70,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 28,
    gender: "female",
    iq_percentile: 0.4381625442,
    avg_physical_attractiveness_rating: 4.5714285714,
    height_inches: 66,
    income_bucket: 2,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Neutral",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 38,
    gender: "male",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 3.8,
    height_inches: 65,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "No",
    has_kids: "Yes",
    attractiveness: "unattractive",
  },
  {
    age: 37,
    gender: "female",
    iq_percentile: 0.4381625442,
    avg_physical_attractiveness_rating: 3.3846153846,
    height_inches: 62,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 31,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 6.7058823529,
    height_inches: 64,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 42,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 5.6,
    height_inches: 68,
    income_bucket: 9,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Neutral",
    politics: "Other",
    wants_kids: "No",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 40,
    gender: "male",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 2.5,
    height_inches: 70,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "No",
    politics: "Moderate",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 30,
    gender: "female",
    iq_percentile: 0.1457597173,
    avg_physical_attractiveness_rating: 1.6363636364,
    height_inches: 66,
    income_bucket: 1,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 35,
    gender: "female",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 2.6,
    height_inches: 65,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 23,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 7,
    height_inches: 65,
    income_bucket: 1,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "No",
    politics: "Socialist",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 32,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 5.8888888889,
    height_inches: 66,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 31,
    gender: "male",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 4.1111111111,
    height_inches: 67,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 32,
    gender: "male",
    iq_percentile: 0.0795053004,
    avg_physical_attractiveness_rating: 3.1428571429,
    height_inches: 69,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 36,
    gender: "female",
    iq_percentile: 0.2818021201,
    avg_physical_attractiveness_rating: 2.0769230769,
    height_inches: 58,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "No",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 27,
    gender: "female",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 4,
    height_inches: 57,
    income_bucket: 3,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 30,
    gender: "female",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 5.6,
    height_inches: 63,
    income_bucket: 3,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Libertarian",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 33,
    gender: "female",
    iq_percentile: 0.0212014134,
    avg_physical_attractiveness_rating: 3,
    height_inches: 67,
    income_bucket: 1,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 27,
    gender: "female",
    iq_percentile: 0.0212014134,
    avg_physical_attractiveness_rating: 4.8888888889,
    height_inches: 66,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 36,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 2.2,
    height_inches: 64,
    income_bucket: 6,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 4,
    political_tolerance: "No",
    politics: "Socialist",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 31,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 3.7058823529,
    height_inches: 71,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 29,
    gender: "male",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 6.1428571429,
    height_inches: 74,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 32,
    gender: "male",
    iq_percentile: 0.1104240283,
    avg_physical_attractiveness_rating: 2.2857142857,
    height_inches: 65,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Neutral",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 27,
    gender: "female",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 4.8888888889,
    height_inches: 71,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Socialist",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 26,
    gender: "male",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 6.7692307692,
    height_inches: 68,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 28,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 4.2,
    height_inches: 68,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 38,
    gender: "female",
    iq_percentile: 0.2818021201,
    avg_physical_attractiveness_rating: 2.0909090909,
    height_inches: 63,
    income_bucket: 2,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Apolitical",
    wants_kids: "No",
    has_kids: "Yes",
    attractiveness: "unattractive",
  },
  {
    age: 33,
    gender: "male",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 3,
    height_inches: 70,
    income_bucket: 6,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 1,
    political_tolerance: "Neutral",
    politics: "Libertarian",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 28,
    gender: "female",
    iq_percentile: 0.2234982332,
    avg_physical_attractiveness_rating: 2.4545454545,
    height_inches: 62,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Neutral",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 31,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 5.1818181818,
    height_inches: 62,
    income_bucket: 6,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 33,
    gender: "male",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 4.8235294118,
    height_inches: 70,
    income_bucket: 7,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 3,
    political_tolerance: "No",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 39,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 4.5454545455,
    height_inches: 66,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "No",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 33,
    gender: "male",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 3.2941176471,
    height_inches: 68,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 31,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 1.5,
    height_inches: 69,
    income_bucket: 3,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Neutral",
    politics: "Liberal",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 32,
    gender: "female",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 3.375,
    height_inches: 64,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 34,
    gender: "male",
    iq_percentile: 0.1104240283,
    avg_physical_attractiveness_rating: 4.5,
    height_inches: 69,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 37,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 3.7777777778,
    height_inches: 62,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 29,
    gender: "female",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 1.8571428571,
    height_inches: 61,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "No",
    politics: "Liberal",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 32,
    gender: "male",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 4.5,
    height_inches: 70,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 31,
    gender: "male",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 2.7692307692,
    height_inches: 69,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 33,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 3.5,
    height_inches: 61,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 34,
    gender: "male",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 4.5,
    height_inches: 64,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 21,
    gender: "male",
    iq_percentile: 0.0212014134,
    avg_physical_attractiveness_rating: 4.8,
    height_inches: 74,
    income_bucket: 3,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 36,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 7,
    height_inches: 68,
    income_bucket: 9,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 3,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 27,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 3.9375,
    height_inches: 64,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 3,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 33,
    gender: "female",
    iq_percentile: 0.0494699647,
    avg_physical_attractiveness_rating: 3.7272727273,
    height_inches: 63,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "unattractive",
  },
  {
    age: 35,
    gender: "female",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 2.6428571429,
    height_inches: 68,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 1,
    political_tolerance: "No",
    politics: "Liberal",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 29,
    gender: "female",
    iq_percentile: 0.2818021201,
    avg_physical_attractiveness_rating: 3.75,
    height_inches: 60,
    income_bucket: 3,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "No",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 36,
    gender: "male",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 5.5454545455,
    height_inches: 74,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 3,
    political_tolerance: "Yes",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 28,
    gender: "male",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 5,
    height_inches: 66,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 29,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 5.1538461538,
    height_inches: 68,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 3,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 27,
    gender: "male",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 2,
    height_inches: 70,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Neutral",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 24,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 3,
    height_inches: 62,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "No",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 33,
    gender: "female",
    iq_percentile: 0.2818021201,
    avg_physical_attractiveness_rating: 5.1666666667,
    height_inches: 67,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Libertarian",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 35,
    gender: "male",
    iq_percentile: 0.1457597173,
    avg_physical_attractiveness_rating: 4.1111111111,
    height_inches: 64,
    income_bucket: 2,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "No",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 31,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 5.1333333333,
    height_inches: 66,
    income_bucket: 2,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 0,
    political_tolerance: "No",
    politics: "Apolitical",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 27,
    gender: "female",
    iq_percentile: 0.2818021201,
    avg_physical_attractiveness_rating: 2.8666666667,
    height_inches: 67,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "No",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 37,
    gender: "male",
    iq_percentile: 0.2234982332,
    avg_physical_attractiveness_rating: 3.7333333333,
    height_inches: 71,
    income_bucket: 3,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Libertarian",
    wants_kids: "No",
    has_kids: "Yes",
    attractiveness: "unattractive",
  },
  {
    age: 40,
    gender: "female",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 2.875,
    height_inches: 61,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 36,
    gender: "male",
    iq_percentile: 0.1802120141,
    avg_physical_attractiveness_rating: 4.875,
    height_inches: 71,
    income_bucket: 3,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 40,
    gender: "male",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 4.9,
    height_inches: 72,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 40,
    gender: "female",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 3.25,
    height_inches: 67,
    income_bucket: 4,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "No",
    has_kids: "Yes",
    attractiveness: "unattractive",
  },
  {
    age: 29,
    gender: "male",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 4.5,
    height_inches: 69,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 36,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 3.6923076923,
    height_inches: 70,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 28,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 4.8,
    height_inches: 70,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 31,
    gender: "male",
    iq_percentile: 0.1104240283,
    avg_physical_attractiveness_rating: 4.125,
    height_inches: 75,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 28,
    gender: "female",
    iq_percentile: 0.2234982332,
    avg_physical_attractiveness_rating: 7.5333333333,
    height_inches: 70,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "No",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 27,
    gender: "female",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 5.2,
    height_inches: 66,
    income_bucket: 1,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "No",
    politics: "Socialist",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 31,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 5.2222222222,
    height_inches: 69,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Neutral",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 32,
    gender: "female",
    iq_percentile: 0.2234982332,
    avg_physical_attractiveness_rating: 4.5454545455,
    height_inches: 59,
    income_bucket: 3,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 32,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 5.2727272727,
    height_inches: 70,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 3,
    political_tolerance: "Yes",
    politics: "Libertarian",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 30,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 4.25,
    height_inches: 66,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 48,
    gender: "female",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 2.8,
    height_inches: 66,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "unattractive",
  },
  {
    age: 34,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 5.7368421053,
    height_inches: 62,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 33,
    gender: "female",
    iq_percentile: 0.2818021201,
    avg_physical_attractiveness_rating: 3.6666666667,
    height_inches: 60,
    income_bucket: 3,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Neutral",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 32,
    gender: "male",
    iq_percentile: 0.1104240283,
    avg_physical_attractiveness_rating: 3,
    height_inches: 74,
    income_bucket: 4,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 28,
    gender: "female",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 4,
    height_inches: 65,
    income_bucket: 1,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 29,
    gender: "female",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 5,
    height_inches: 63,
    income_bucket: 2,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "No",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 36,
    gender: "male",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 6.8571428571,
    height_inches: 66,
    income_bucket: 8,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Neutral",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 30,
    gender: "male",
    iq_percentile: 0.0795053004,
    avg_physical_attractiveness_rating: 6.4166666667,
    height_inches: 70,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 25,
    gender: "female",
    iq_percentile: 0.2234982332,
    avg_physical_attractiveness_rating: 4.8666666667,
    height_inches: 59,
    income_bucket: 3,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 27,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 5.4,
    height_inches: 60,
    income_bucket: 2,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "No",
    politics: "Liberal",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 29,
    gender: "male",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 6.6666666667,
    height_inches: 67,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 33,
    gender: "male",
    iq_percentile: 0.4381625442,
    avg_physical_attractiveness_rating: 4.7,
    height_inches: 69,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 35,
    gender: "male",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 4.5833333333,
    height_inches: 72,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Libertarian",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 38,
    gender: "female",
    iq_percentile: 0.4381625442,
    avg_physical_attractiveness_rating: 4.3529411765,
    height_inches: 65,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 4,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 33,
    gender: "female",
    iq_percentile: 0.1104240283,
    avg_physical_attractiveness_rating: 6.375,
    height_inches: 64,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 27,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 2.7,
    height_inches: 64,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 29,
    gender: "female",
    iq_percentile: 0.0212014134,
    avg_physical_attractiveness_rating: 3.9166666667,
    height_inches: 68,
    income_bucket: 2,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Socialist",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "unattractive",
  },
  {
    age: 30,
    gender: "male",
    iq_percentile: 0.4381625442,
    avg_physical_attractiveness_rating: 3.8125,
    height_inches: 69,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 31,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 3.2727272727,
    height_inches: 71,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 27,
    gender: "male",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 4.2666666667,
    height_inches: 67,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Apolitical",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 36,
    gender: "male",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 5.125,
    height_inches: 70,
    income_bucket: 9,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 27,
    gender: "male",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 5.9230769231,
    height_inches: 69,
    income_bucket: 8,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 4,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 29,
    gender: "male",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 5.7272727273,
    height_inches: 73,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 31,
    gender: "male",
    iq_percentile: 0.1457597173,
    avg_physical_attractiveness_rating: 3.7333333333,
    height_inches: 71,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 35,
    gender: "female",
    iq_percentile: 0.1802120141,
    avg_physical_attractiveness_rating: 4.0769230769,
    height_inches: 67,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Libertarian",
    wants_kids: "No",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 30,
    gender: "female",
    iq_percentile: 0.4381625442,
    avg_physical_attractiveness_rating: 5.2222222222,
    height_inches: 66,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 46,
    gender: "male",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 4.3,
    height_inches: 69,
    income_bucket: 9,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 27,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 2.6363636364,
    height_inches: 63,
    income_bucket: 2,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "No",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 28,
    gender: "male",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 3.4615384615,
    height_inches: 67,
    income_bucket: 3,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 39,
    gender: "female",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 3.6923076923,
    height_inches: 68,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "No",
    politics: "Socialist",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 28,
    gender: "male",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 2.8571428571,
    height_inches: 66,
    income_bucket: 8,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Neutral",
    politics: "Moderate",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 33,
    gender: "male",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 4.5789473684,
    height_inches: 70,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 35,
    gender: "female",
    iq_percentile: 0.2234982332,
    avg_physical_attractiveness_rating: 6.3333333333,
    height_inches: 61,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "attractive",
  },
  {
    age: 36,
    gender: "female",
    iq_percentile: 0.4381625442,
    avg_physical_attractiveness_rating: 4.8823529412,
    height_inches: 61,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 27,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 4.7333333333,
    height_inches: 63,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 33,
    gender: "male",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 6.8333333333,
    height_inches: 72,
    income_bucket: 2,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 25,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 6.9090909091,
    height_inches: 61,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 29,
    gender: "female",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 5.2222222222,
    height_inches: 66,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 23,
    gender: "female",
    iq_percentile: 0.2234982332,
    avg_physical_attractiveness_rating: 5.7777777778,
    height_inches: 68,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 34,
    gender: "male",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 2.9166666667,
    height_inches: 75,
    income_bucket: 3,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 28,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 3.875,
    height_inches: 70,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 37,
    gender: "male",
    iq_percentile: 0.1457597173,
    avg_physical_attractiveness_rating: 2.2857142857,
    height_inches: 71,
    income_bucket: 2,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 25,
    gender: "female",
    iq_percentile: 0.1457597173,
    avg_physical_attractiveness_rating: 1.4285714286,
    height_inches: 65,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 34,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 2.7272727273,
    height_inches: 63,
    income_bucket: 3,
    uses_nicotine: "Yes",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 26,
    gender: "female",
    iq_percentile: 0.2818021201,
    avg_physical_attractiveness_rating: 5.1428571429,
    height_inches: 69,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 33,
    gender: "female",
    iq_percentile: 0.4381625442,
    avg_physical_attractiveness_rating: 4,
    height_inches: 64,
    income_bucket: 3,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "No",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 41,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 3.5,
    height_inches: 70,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 38,
    gender: "female",
    iq_percentile: 0.2234982332,
    avg_physical_attractiveness_rating: 2.5,
    height_inches: 66,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Apolitical",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 41,
    gender: "male",
    iq_percentile: 0.4381625442,
    avg_physical_attractiveness_rating: 4.8333333333,
    height_inches: 69,
    income_bucket: 4,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Libertarian",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 36,
    gender: "female",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 3.3333333333,
    height_inches: 62,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 35,
    gender: "male",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 3.4375,
    height_inches: 70,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 31,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 6.5,
    height_inches: 67,
    income_bucket: 4,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Neutral",
    politics: "Apolitical",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "attractive",
  },
  {
    age: 21,
    gender: "male",
    iq_percentile: 0.1104240283,
    avg_physical_attractiveness_rating: 2.5,
    height_inches: 74,
    income_bucket: 1,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "No",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 33,
    gender: "female",
    iq_percentile: 0.2234982332,
    avg_physical_attractiveness_rating: 6.2,
    height_inches: 66,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 34,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 2.4444444444,
    height_inches: 60,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 29,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 3.9333333333,
    height_inches: 64,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 22,
    gender: "male",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 3.7142857143,
    height_inches: 73,
    income_bucket: 1,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Neutral",
    politics: "Socialist",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 32,
    gender: "female",
    iq_percentile: 0.4381625442,
    avg_physical_attractiveness_rating: 3,
    height_inches: 63,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 31,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 6.7,
    height_inches: 66,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "No",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 28,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 7.5,
    height_inches: 60,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Neutral",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 33,
    gender: "male",
    iq_percentile: 0.0212014134,
    avg_physical_attractiveness_rating: 2.55,
    height_inches: 74,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Neutral",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 38,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 3.35,
    height_inches: 69,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Libertarian",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 31,
    gender: "female",
    iq_percentile: 0.1104240283,
    avg_physical_attractiveness_rating: 4.0909090909,
    height_inches: 67,
    income_bucket: 1,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 4,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 33,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 3.25,
    height_inches: 73,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 39,
    gender: "female",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 5.1666666667,
    height_inches: 68,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 34,
    gender: "male",
    iq_percentile: 0.1104240283,
    avg_physical_attractiveness_rating: 4.5,
    height_inches: 76,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 3,
    political_tolerance: "Yes",
    politics: "Libertarian",
    wants_kids: "No",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 35,
    gender: "male",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 3.7272727273,
    height_inches: 72,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "No",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 38,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 4.1111111111,
    height_inches: 63,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "No",
    politics: "Liberal",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 35,
    gender: "female",
    iq_percentile: 0.0795053004,
    avg_physical_attractiveness_rating: 4.5714285714,
    height_inches: 60,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 32,
    gender: "female",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 4.1538461538,
    height_inches: 69,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "No",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 29,
    gender: "female",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 5.125,
    height_inches: 69,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "No",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 42,
    gender: "male",
    iq_percentile: 0.4381625442,
    avg_physical_attractiveness_rating: 5,
    height_inches: 67,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 61,
    gender: "female",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 2.75,
    height_inches: 64,
    income_bucket: 9,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Neutral",
    politics: "Socialist",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "unattractive",
  },
  {
    age: 23,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 5.3636363636,
    height_inches: 68,
    income_bucket: 2,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 30,
    gender: "male",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 7.0666666667,
    height_inches: 73,
    income_bucket: 2,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 39,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 5.9375,
    height_inches: 61,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 36,
    gender: "male",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 4.7777777778,
    height_inches: 67,
    income_bucket: 2,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 29,
    gender: "female",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 6.1,
    height_inches: 62,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 3,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 34,
    gender: "male",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 5.9166666667,
    height_inches: 71,
    income_bucket: 8,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Neutral",
    politics: "Socialist",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 30,
    gender: "male",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 3.1538461538,
    height_inches: 75,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 40,
    gender: "male",
    iq_percentile: 0.1802120141,
    avg_physical_attractiveness_rating: 3.6363636364,
    height_inches: 67,
    income_bucket: 3,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Neutral",
    politics: "Moderate",
    wants_kids: "No",
    has_kids: "Yes",
    attractiveness: "unattractive",
  },
  {
    age: 33,
    gender: "male",
    iq_percentile: 0.1104240283,
    avg_physical_attractiveness_rating: 3.8,
    height_inches: 72,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 34,
    gender: "female",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 3.3846153846,
    height_inches: 64,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 4,
    political_tolerance: "No",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 37,
    gender: "female",
    iq_percentile: 0.4381625442,
    avg_physical_attractiveness_rating: 5.2,
    height_inches: 63,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Apolitical",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 36,
    gender: "female",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 5.2,
    height_inches: 65,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Apolitical",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 27,
    gender: "male",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 3.2857142857,
    height_inches: 68,
    income_bucket: 1,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 36,
    gender: "male",
    iq_percentile: 0.2818021201,
    avg_physical_attractiveness_rating: 3,
    height_inches: 64,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 33,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 3.3333333333,
    height_inches: 68,
    income_bucket: 5,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Libertarian",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 35,
    gender: "male",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 5.6153846154,
    height_inches: 69,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 30,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 4.1666666667,
    height_inches: 67,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 31,
    gender: "female",
    iq_percentile: 0.4381625442,
    avg_physical_attractiveness_rating: 5.5,
    height_inches: 65,
    income_bucket: 2,
    uses_nicotine: "Yes",
    uses_alcohol: "No",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 34,
    gender: "female",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 7.4,
    height_inches: 68,
    income_bucket: 4,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 0,
    political_tolerance: "No",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 42,
    gender: "male",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 4.6,
    height_inches: 72,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Libertarian",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 38,
    gender: "male",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 3.1818181818,
    height_inches: 68,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 40,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 4.4666666667,
    height_inches: 74,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 25,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 4,
    height_inches: 63,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 35,
    gender: "male",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 6.6153846154,
    height_inches: 69,
    income_bucket: 8,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 37,
    gender: "male",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 2.5714285714,
    height_inches: 69,
    income_bucket: 8,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Libertarian",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "unattractive",
  },
  {
    age: 23,
    gender: "male",
    iq_percentile: 0.2818021201,
    avg_physical_attractiveness_rating: 4,
    height_inches: 69,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 33,
    gender: "male",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 2.3636363636,
    height_inches: 70,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 37,
    gender: "male",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 4.0833333333,
    height_inches: 73,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 4,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 28,
    gender: "male",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 4.7777777778,
    height_inches: 66,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 23,
    gender: "male",
    iq_percentile: 0.1802120141,
    avg_physical_attractiveness_rating: 3.1666666667,
    height_inches: 74,
    income_bucket: 3,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Libertarian",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 36,
    gender: "male",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 2.6666666667,
    height_inches: 70,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "unattractive",
  },
  {
    age: 22,
    gender: "female",
    iq_percentile: 0.1104240283,
    avg_physical_attractiveness_rating: 2.3333333333,
    height_inches: 60,
    income_bucket: 3,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 31,
    gender: "female",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 5,
    height_inches: 69,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 32,
    gender: "male",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 4.5555555556,
    height_inches: 67,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "No",
    politics: "Socialist",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 25,
    gender: "female",
    iq_percentile: 0.1802120141,
    avg_physical_attractiveness_rating: 4.1111111111,
    height_inches: 68,
    income_bucket: 2,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 29,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 3.1764705882,
    height_inches: 66,
    income_bucket: 1,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 53,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 2,
    height_inches: 70,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "No",
    has_kids: "Yes",
    attractiveness: "unattractive",
  },
  {
    age: 30,
    gender: "male",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 6,
    height_inches: 67,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 4,
    political_tolerance: "Yes",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 31,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 4.875,
    height_inches: 66,
    income_bucket: 8,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 29,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 4.7142857143,
    height_inches: 63,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "No",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 27,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 6.5,
    height_inches: 66,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 36,
    gender: "male",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 5.8666666667,
    height_inches: 65,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 37,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 5,
    height_inches: 62,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 34,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 3.6666666667,
    height_inches: 73,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Neutral",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 33,
    gender: "female",
    iq_percentile: 0.2818021201,
    avg_physical_attractiveness_rating: 3.4090909091,
    height_inches: 70,
    income_bucket: 4,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Apolitical",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 38,
    gender: "male",
    iq_percentile: 0.2818021201,
    avg_physical_attractiveness_rating: 4.5,
    height_inches: 68,
    income_bucket: 8,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 3,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 32,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 4.6153846154,
    height_inches: 74,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 52,
    gender: "female",
    iq_percentile: 0.1802120141,
    avg_physical_attractiveness_rating: 2,
    height_inches: 67,
    income_bucket: 2,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 39,
    gender: "male",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 4.4,
    height_inches: 71,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Neutral",
    politics: "Socialist",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 42,
    gender: "male",
    iq_percentile: 0.1802120141,
    avg_physical_attractiveness_rating: 4.8,
    height_inches: 70,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 40,
    gender: "male",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 5.25,
    height_inches: 65,
    income_bucket: 3,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 34,
    gender: "male",
    iq_percentile: 0.4381625442,
    avg_physical_attractiveness_rating: 5.5,
    height_inches: 65,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Neutral",
    politics: "Apolitical",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 25,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 6,
    height_inches: 62,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "No",
    politics: "Socialist",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 36,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 5.2727272727,
    height_inches: 67,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "No",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 30,
    gender: "male",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 4.4615384615,
    height_inches: 70,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Apolitical",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 33,
    gender: "male",
    iq_percentile: 0.2234982332,
    avg_physical_attractiveness_rating: 5.4444444444,
    height_inches: 65,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 36,
    gender: "male",
    iq_percentile: 0.0212014134,
    avg_physical_attractiveness_rating: 5.2307692308,
    height_inches: 69,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Apolitical",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 25,
    gender: "male",
    iq_percentile: 0.1802120141,
    avg_physical_attractiveness_rating: 3,
    height_inches: 75,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 31,
    gender: "male",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 3.4210526316,
    height_inches: 68,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 29,
    gender: "female",
    iq_percentile: 0.2234982332,
    avg_physical_attractiveness_rating: 6.4166666667,
    height_inches: 65,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 36,
    gender: "male",
    iq_percentile: 0.1104240283,
    avg_physical_attractiveness_rating: 3.7,
    height_inches: 73,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Socialist",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 33,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 6.2,
    height_inches: 65,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "No",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 30,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 5.75,
    height_inches: 65,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 3,
    political_tolerance: "Neutral",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 56,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 2.3333333333,
    height_inches: 68,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "No",
    has_kids: "Yes",
    attractiveness: "unattractive",
  },
  {
    age: 32,
    gender: "male",
    iq_percentile: 0.2818021201,
    avg_physical_attractiveness_rating: 5.1111111111,
    height_inches: 66,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 27,
    gender: "male",
    iq_percentile: 0.1457597173,
    avg_physical_attractiveness_rating: 6.125,
    height_inches: 69,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 34,
    gender: "male",
    iq_percentile: 0.0212014134,
    avg_physical_attractiveness_rating: 3.4285714286,
    height_inches: 68,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "No",
    politics: "Socialist",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "unattractive",
  },
  {
    age: 24,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 4.6363636364,
    height_inches: 64,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "No",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 28,
    gender: "male",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 4,
    height_inches: 70,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 35,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 5.5,
    height_inches: 60,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 35,
    gender: "male",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 3.6428571429,
    height_inches: 69,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 3,
    political_tolerance: "Yes",
    politics: "Libertarian",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 52,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 5,
    height_inches: 71,
    income_bucket: 8,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "No",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 34,
    gender: "female",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 1.375,
    height_inches: 59,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 45,
    gender: "female",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 5,
    height_inches: 68,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Neutral",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 33,
    gender: "female",
    iq_percentile: 0.2818021201,
    avg_physical_attractiveness_rating: 3.6923076923,
    height_inches: 56,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 32,
    gender: "female",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 6,
    height_inches: 68,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 27,
    gender: "female",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 5.3333333333,
    height_inches: 68,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 3,
    political_tolerance: "Neutral",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 42,
    gender: "male",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 2.1111111111,
    height_inches: 76,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "No",
    politics: "Liberal",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 27,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 4.4166666667,
    height_inches: 68,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Neutral",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 27,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 5.375,
    height_inches: 67,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "No",
    politics: "Liberal",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 30,
    gender: "male",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 5.8,
    height_inches: 69,
    income_bucket: 9,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Libertarian",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 32,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 3,
    height_inches: 64,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Neutral",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "unattractive",
  },
  {
    age: 37,
    gender: "male",
    iq_percentile: 0.2818021201,
    avg_physical_attractiveness_rating: 4.6666666667,
    height_inches: 70,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "No",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 27,
    gender: "male",
    iq_percentile: 0.0795053004,
    avg_physical_attractiveness_rating: 2.75,
    height_inches: 69,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Neutral",
    politics: "Moderate",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 34,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 4.7692307692,
    height_inches: 62,
    income_bucket: 5,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Neutral",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 29,
    gender: "male",
    iq_percentile: 0.0494699647,
    avg_physical_attractiveness_rating: 4.625,
    height_inches: 73,
    income_bucket: 2,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 28,
    gender: "female",
    iq_percentile: 0.2818021201,
    avg_physical_attractiveness_rating: 3.125,
    height_inches: 64,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "No",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 26,
    gender: "female",
    iq_percentile: 0.4381625442,
    avg_physical_attractiveness_rating: 5.4545454545,
    height_inches: 64,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 30,
    gender: "male",
    iq_percentile: 0.1457597173,
    avg_physical_attractiveness_rating: 4.4,
    height_inches: 71,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 28,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 7,
    height_inches: 72,
    income_bucket: 4,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 30,
    gender: "male",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 4.9090909091,
    height_inches: 71,
    income_bucket: 9,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 34,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 4,
    height_inches: 70,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Socialist",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 33,
    gender: "male",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 6.3,
    height_inches: 71,
    income_bucket: 8,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Neutral",
    politics: "Apolitical",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 32,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 4.4,
    height_inches: 68,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 22,
    gender: "female",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 5.6,
    height_inches: 64,
    income_bucket: 2,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 3,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 25,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 4.7272727273,
    height_inches: 68,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 34,
    gender: "female",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 4.7272727273,
    height_inches: 69,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 30,
    gender: "female",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 5.625,
    height_inches: 67,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 25,
    gender: "female",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 2.5714285714,
    height_inches: 69,
    income_bucket: 1,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Apolitical",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 31,
    gender: "female",
    iq_percentile: 0.4381625442,
    avg_physical_attractiveness_rating: 6.6,
    height_inches: 66,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 3,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 27,
    gender: "male",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 2.9523809524,
    height_inches: 74,
    income_bucket: 3,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 61,
    gender: "male",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 1.7777777778,
    height_inches: 70,
    income_bucket: 2,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 28,
    gender: "male",
    iq_percentile: 0.4381625442,
    avg_physical_attractiveness_rating: 3,
    height_inches: 64,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 28,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 6.4285714286,
    height_inches: 61,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Socialist",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 28,
    gender: "female",
    iq_percentile: 0.2234982332,
    avg_physical_attractiveness_rating: 3.6,
    height_inches: 63,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 30,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 6.4285714286,
    height_inches: 68,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "No",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 29,
    gender: "male",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 4.9,
    height_inches: 70,
    income_bucket: 5,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 34,
    gender: "male",
    iq_percentile: 0.0795053004,
    avg_physical_attractiveness_rating: 3.3125,
    height_inches: 69,
    income_bucket: 4,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 33,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 4.3076923077,
    height_inches: 67,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 39,
    gender: "female",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 5.1428571429,
    height_inches: 70,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 48,
    gender: "female",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 2,
    height_inches: 71,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 3,
    political_tolerance: "No",
    politics: "Moderate",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 62,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 3.1111111111,
    height_inches: 63,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "No",
    has_kids: "Yes",
    attractiveness: "unattractive",
  },
  {
    age: 30,
    gender: "male",
    iq_percentile: 0.1457597173,
    avg_physical_attractiveness_rating: 4,
    height_inches: 75,
    income_bucket: 8,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Neutral",
    politics: "Apolitical",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 26,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 5.7,
    height_inches: 67,
    income_bucket: 1,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 3,
    political_tolerance: "Neutral",
    politics: "Liberal",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 34,
    gender: "female",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 6.3333333333,
    height_inches: 62,
    income_bucket: 8,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "No",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 24,
    gender: "male",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 3.8571428571,
    height_inches: 69,
    income_bucket: 3,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Neutral",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 34,
    gender: "female",
    iq_percentile: 0.0795053004,
    avg_physical_attractiveness_rating: 3.5,
    height_inches: 56,
    income_bucket: 2,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "No",
    politics: "Other",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 34,
    gender: "male",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 5.8,
    height_inches: 70,
    income_bucket: 6,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 44,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 4.7692307692,
    height_inches: 71,
    income_bucket: 8,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Neutral",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 33,
    gender: "male",
    iq_percentile: 0.2818021201,
    avg_physical_attractiveness_rating: 3.1666666667,
    height_inches: 70,
    income_bucket: 8,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 33,
    gender: "male",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 4.2857142857,
    height_inches: 70,
    income_bucket: 8,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 28,
    gender: "male",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 3.4117647059,
    height_inches: 68,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "No",
    politics: "Socialist",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 29,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 2.8333333333,
    height_inches: 65,
    income_bucket: 3,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 0,
    political_tolerance: "Neutral",
    politics: "Socialist",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 49,
    gender: "female",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 2.8,
    height_inches: 64,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "No",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "unattractive",
  },
  {
    age: 30,
    gender: "male",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 4.7142857143,
    height_inches: 71,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 27,
    gender: "female",
    iq_percentile: 0.3586572438,
    avg_physical_attractiveness_rating: 5.75,
    height_inches: 66,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Neutral",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 34,
    gender: "female",
    iq_percentile: 0.2234982332,
    avg_physical_attractiveness_rating: 5.2666666667,
    height_inches: 68,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 34,
    gender: "female",
    iq_percentile: 0.2234982332,
    avg_physical_attractiveness_rating: 5.8333333333,
    height_inches: 61,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 28,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 3.5555555556,
    height_inches: 61,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "No",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 30,
    gender: "male",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 4.5,
    height_inches: 72,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Apolitical",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 29,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 3.4444444444,
    height_inches: 68,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 28,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 3.3333333333,
    height_inches: 67,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 35,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 3.4666666667,
    height_inches: 70,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 26,
    gender: "female",
    iq_percentile: 0.1457597173,
    avg_physical_attractiveness_rating: 2.3333333333,
    height_inches: 60,
    income_bucket: 2,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Apolitical",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 27,
    gender: "female",
    iq_percentile: 0.2818021201,
    avg_physical_attractiveness_rating: 4,
    height_inches: 69,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Neutral",
    politics: "Liberal",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 28,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 5,
    height_inches: 66,
    income_bucket: 3,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Socialist",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 31,
    gender: "female",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 6.6666666667,
    height_inches: 60,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "No",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "attractive",
  },
  {
    age: 29,
    gender: "female",
    iq_percentile: 0.6669611307,
    avg_physical_attractiveness_rating: 6.2,
    height_inches: 67,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Neutral",
    politics: "Apolitical",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "attractive",
  },
  {
    age: 38,
    gender: "female",
    iq_percentile: 0.4381625442,
    avg_physical_attractiveness_rating: 4.125,
    height_inches: 62,
    income_bucket: 4,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "Yes",
    attractiveness: "average",
  },
  {
    age: 41,
    gender: "male",
    iq_percentile: 0.2234982332,
    avg_physical_attractiveness_rating: 2.5,
    height_inches: 67,
    income_bucket: 5,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Other",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 39,
    gender: "female",
    iq_percentile: 0.5406360424,
    avg_physical_attractiveness_rating: 4.625,
    height_inches: 70,
    income_bucket: 7,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "No",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 41,
    gender: "male",
    iq_percentile: 0.953180212,
    avg_physical_attractiveness_rating: 4.2666666667,
    height_inches: 69,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "No",
    uses_marijuana: "No",
    has_used_psychedelics: "Yes",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "No",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 27,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 5.3846153846,
    height_inches: 72,
    income_bucket: 8,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "No",
    sex_partners_score: 0,
    political_tolerance: "Yes",
    politics: "Conservative",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 33,
    gender: "female",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 4.4117647059,
    height_inches: 64,
    income_bucket: 5,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 37,
    gender: "male",
    iq_percentile: 0.1457597173,
    avg_physical_attractiveness_rating: 3.5714285714,
    height_inches: 68,
    income_bucket: 4,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 2,
    political_tolerance: "Neutral",
    politics: "Liberal",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "unattractive",
  },
  {
    age: 42,
    gender: "male",
    iq_percentile: 0.816254417,
    avg_physical_attractiveness_rating: 4.5555555556,
    height_inches: 68,
    income_bucket: 6,
    uses_nicotine: "No",
    uses_alcohol: "Yes",
    uses_marijuana: "No",
    has_used_psychedelics: "No",
    sex_partners_score: 1,
    political_tolerance: "Yes",
    politics: "Moderate",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
  {
    age: 32,
    gender: "male",
    iq_percentile: 0.1802120141,
    avg_physical_attractiveness_rating: 6,
    height_inches: 68,
    income_bucket: 5,
    uses_nicotine: "Yes",
    uses_alcohol: "Yes",
    uses_marijuana: "Yes",
    has_used_psychedelics: "Yes",
    sex_partners_score: 2,
    political_tolerance: "Yes",
    politics: "Socialist",
    wants_kids: "Yes",
    has_kids: "No",
    attractiveness: "average",
  },
];
